<template>
  <div class="notifications">
    <notification v-for="(item, index) in listNotifications"
        :key="index"
        :header-text="item.header"
        :body-text="item.body"
        :cookie-name="item.name"
        :class-name="item.className"
    />
  </div>
</template>

<script>
import notification from './notification';

export default {
  name: 'notifications',
  components: {
    'notification': notification
  },
  data: function () {
    return {
      listNotifications: [
        {
          body: 'Приложение находится в активной стадии тестирования. Пожалуйста, сообщайте нам о своих пожеланиях в его развии а так же об ошибках, которые у вас возникают. <br><br> <a target="_blank" href="https://t.me/chelnokov1997">Телеграм разрабочтка</a>',
          name: 'beta',
          className: 'beta'
        }
      ]
    }
  },
}
</script>

<style lang="scss">
.notifications {
  position: absolute;
  bottom: 30px;
  right: 30px;
}
</style>

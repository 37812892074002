import { PnnApi } from "../../api/pnn-api";

const template = {
  state: () => ({
    templateList: []
  }),
  mutations: {
    SET_TEMPLATE_LIST: function (state, data) {
      state.templateList = data;
    },
    PUSH_TEMPLATE_LIST: function (state, data) {
      state.templateList.push(data)
    },
    REMOVE_ALL_ACTIVE_FLAG: function (state) {
      state.templateList.forEach(item => {
        item.is_active = false;
      })
    },
    UPDATE_TEMPLATE_ITEM: function (state, data) {
      const { id } = data;
      const item = state.templateList.find(item => item.id === id);

      for (let key in item ) {
        if (data[key]) {
          item[key] = data[key]
        }
      }
    },
    REMOVE_ITEM: function (state, id) {
      state.templateList = state.templateList.filter(item => item.id !== id)
    }
  },
  actions: {
    getTemplates: function(context) {
      return PnnApi.getTemplates()
          .then(templateList => {
            context.commit('SET_TEMPLATE_LIST', templateList);

            return templateList;
          })
    },
    createTemplate: function(context, payload) {
      return PnnApi.createTemplate(payload)
          .then(res => {
            context.commit('PUSH_TEMPLATE_LIST', res)

            return res;
          })
    },
    updateTemplate: function(context, payload) {
      return PnnApi.updateTemplate(payload)
          .then(data => {
            const item = data.raw[0];

            context.commit('UPDATE_TEMPLATE_ITEM', {
              id: item.id,
              title: item.title,
              template: item.template
            })

            return data.raw[0];
          })
    },
    activateTemplate: function (context, payload) {
      return PnnApi.activateTemplate(payload)
          .then(data => {
            context.commit('REMOVE_ALL_ACTIVE_FLAG')
            context.commit('UPDATE_TEMPLATE_ITEM', {
              id: data.id,
              is_active: true
            })

            return data;
          })
    },
    deactivateAll: function (context) {
      return PnnApi.deactivateAll()
        .then(() => {
          context.commit('REMOVE_ALL_ACTIVE_FLAG')
        })
    },
    deleteTemplate: function (context, payload) {
      return PnnApi.deleteTemplate(payload)
          .then(data => {
            context.commit('REMOVE_ITEM', payload.templateId)

            return data;
          })
    }

  },
  getters: {

  }
}

export default template;

import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import account from './modules/account'
import user from './modules/user'
import subscriptions from './modules/subscriptions'
import modalForm from './modules/modal-form';
import template from './modules/template'
import products from './modules/products';
import { PnnApi } from "../api/pnn-api";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
    initAccount: function() {
      return PnnApi.initAccount()
          .catch(error => {
            throw new Error(error);
          })
    }
  },
  modules: {
    auth, account, user, subscriptions, template, modalForm, products
  }
})

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Paginate from 'vuejs-paginate';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import DefaultLayout from './layouts/default-layout.vue';
import AuthLayout from './layouts/auth-layout.vue';
import EmptyLayout from './layouts/empty-loyaut';
import appMessage from './components/base-ui/app-message';

import VueApexCharts from 'vue-apexcharts';

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)


Vue.component('default-layout', DefaultLayout)
Vue.component('auth-layout', AuthLayout)
Vue.component('empty-layout', EmptyLayout)
Vue.component('app-message', appMessage)

Vue.component('paginate', Paginate)

Sentry.init({
  Vue,
  dsn: "https://1e5ba4659f94438f9de5843fd9777790@o1236745.ingest.sentry.io/4504146764169216",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "pnnser.ru", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

Vue.config.productionTip = false
Vue.config.silent = true

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="header">
    <div class="header__btns">
      <app-button @click="$router.push('/init-setting')" width="300px" value="Настройка приложения" />
<!--      <app-button @click="$router.push('/sms')" width="300px" value="sms уведомления" />-->
    </div>

    <div class="header__account">
      <div class="header__user">
        <div class="header__user-content">
          <div v-if="userData && userData.name" class="header__user-email">{{ userData.name }}</div>
          <div v-if="userData && userData.id" class="header__user-status">Идентификатор: {{ userData.id }}</div>
          <div v-if="userData" class="header__user-status">Статус: {{ userData.is_general ? 'Администратор' : 'Младший администратор' }}</div>
        </div>
        <div class="header__user-icon">
          <img class="header__user-icon-img" :src="userIcon" alt="">
        </div>
      </div>

      <div @click="logout" title="Выйти" class="header__logout">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9" stroke="#C9CED6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16 17L21 12L16 7" stroke="#C9CED6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M21 12H9" stroke="#C9CED6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>

  </div>
</template>

<script>
import appButton from './base-ui/app-button'
export default {
  name: 'app-header',
  components: {
    'app-button': appButton
  },
  data: function () {
    return {
      userIcon: require('../assets/icons/user.png')
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logoutAccount');
    },
    getUserData: async function () {
      // Если нет, то делаем запрос
      if (! this.$store.state.user.userData) {
        this.$store.dispatch('getUserData')
          .then(response => {
            if (response && response.statusCode && response.statusCode === 401) {
              return this.$store.dispatch('logoutAccount')
            }
          })
      }
    },
    getUserAccountData: async function () {
      // Если нет, то делаем запрос
      if (! Object.keys(this.$store.state.account.accountData).length) {
        this.$store.dispatch('getAccountData')
            .then(response => {
              if (response && response.statusCode && response.statusCode === 401) {
                return this.$store.dispatch('logoutAccount')
              }
            })
      }
    },

    getData: function() {
      this.getUserData();
      this.getUserAccountData();
    }
  },
  computed: {
    userData: function () {
      return this.$store.state.user.userData;
    }
  },
  mounted() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  background-color: #fff;
  padding: 0 56px;
  &__account {
    display: flex;
    align-items: center;
  }
  &__user {
    position: relative;
    display: flex;
    align-items: center;
    text-align: right;
    margin-right: 72px;
    &:after {
      content: '';
      position: absolute;
      left: calc(100% + 36px);
      top: 50%;
      transform: translateY(-50%);
      height: 64px;
      width: 1px;
      background-color: var(--color-border);
    }
  }
  &__user-content {
    margin-right: 14px;
  }
  &__btns {
    display: flex;

    & > * {
      margin-right: 20px;
    }
  }
  &__user-email {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    letter-spacing: 0.01em;
    color: #000000;
  }
  &__user-status {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    letter-spacing: 0.01em;
    color: #A6ACBE;
    margin-top: 4px;
  }
  &__user-icon-img {
    width: 50px;
    height: 50px;
    display: block;
  }
  &__logout {
    cursor: pointer;
  }
}
</style>

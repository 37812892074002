<template>
  <div class="empty"></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>

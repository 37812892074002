<template>
  <div class="pnn-account">
    <div class="pnn-account__menu">
      <app-menu />
    </div>
    <div class="pnn-account__content">
      <app-header />
      <div class="pnn-account__content-page">
        <slot />
        <notifications />
        <social />
      </div>
    </div>
  </div>
</template>

<script>
import appHeader from '../components/app-header'
import appMenu from '../components/app-menu';
import notifications from '../components/notifications/notifications';
import social from "../components/social";

export default {
  components: {
    social,
    'app-header': appHeader,
    'app-menu': appMenu,
    notifications,
  }
}
</script>


<style lang="scss" scoped>
.pnn-account {
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  &__menu {
    height: 100vh;
  }
  &__content {
    flex-grow: 1;
  }
  &__content-page {
    //padding: 20px;

    height: calc(100vh - 100px);
    overflow-y: scroll;
    padding-right: 20px;
  }
}
</style>

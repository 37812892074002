<template>
  <div class="auth">
    <div class="auth__container">
      <div class="auth__wrap">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.auth {
  height: 100vh;
  width: 100%;
  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>

import { PnnApi } from "../../api/pnn-api";

const auth = {
  state: () => ({
    settings: {},
    html: '',
  }),
  mutations: {
    SET_SETTINGS: function (state, data) {
      state.settings = data;
    },
    UPDATE_HTML: function (state, html) {
      state.html = html;
    }
  },
  actions: {
    getHTMLForm: function (context, payload) {
      const { insalesId } = payload;

      PnnApi.getModalBuild(insalesId)
          .then(res => {
            if (res.html) {
              console.log('res.html', res.html)
              context.commit('UPDATE_HTML', res.html);
              return res;
            }

            throw new Error(res);
          })
          .catch(error => {
            console.log('error', error)
            throw error;
          })
    },
    getFormSettings: function(context) {
        return PnnApi.getFormSettings()
            .then(res => {
              if (res.settings) {
                context.commit('SET_SETTINGS', JSON.parse(res.settings));

                return res;
              }

              throw new Error(res);
            })
    },
    updateFormSettings: function (context, payload) {
      const { body } = payload;
      return PnnApi.updateFormSettings(body)
          .then(res => {
            if (res.html) {
              context.commit('UPDATE_HTML', res.html)
              return res;
            }

            throw new Error(res);
          })
          .catch(error => { throw error; })
    }
  },
  getters: {

  }
}

export default auth;

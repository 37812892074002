import { PnnApi } from "../../api/pnn-api";

const subscriptions = {
  state: () => ({
    subscriptionFilters: {},
    subscriptionStat: {},
    subscriptionsList: [],
    subscriptionsCounter: undefined,
    subscriptionsAnalytics: {},
    subscriptionsAnalyticsFilters: {},
    subscriptionsParams: {},
    subscriptionsPoints: [],
    subscriptionsNewList: [],
  }),
  mutations: {
    SET_PARAMS: function (state, data) {
      state.subscriptionsParams = data;
    },
    SET_POINTS: function (state, data) {
      state.subscriptionsPoints = data;
    },
    SET_ANALYTIC_FILTERS: function (state, data) {
      state.subscriptionsAnalyticsFilters = data;
    },
    SET_ANALYTICS: function (state, data) {
      state.subscriptionsAnalytics = data;
    },
    SET_SUBSCRIPTION_STAT: function (state, data) {
      state.subscriptionStat = data;
    },
    PUSH_SUBSCRIPTION: function (state, subscription) {
      state.subscriptionsList.push(subscription);
    },
    SET_SUBSCRIPTIONS: function (state, data) {
      state.subscriptionsList = data;
    },
    SET_SUBSCRIPTIONS_COUNTER: function (state, data) {
      state.subscriptionsCounter = data;
    },
    SET_SUBSCRIPTION_FILTER: function (state, data) {
      for (const key in data) {
        if (data[key]) {
          state.subscriptionFilters[key] = data[key]
        } else {
          delete state.subscriptionFilters[key]
        }
      }
    },
    SET_SUBSCRIPTIONS_NEW_LIST: function (state, list) {
      state.subscriptionsNewList = list;
    }
  },
  actions: {
    getParams: function (context) {
      return PnnApi.getSubscriptionParams()
          .then(response => {
                if (Object.keys(response).length && ! response.message && response.message !== 'Internal server error') {
                  context.commit('SET_PARAMS', response)
                  return response;
                }

                throw new Error(response);
          })
          .catch(error => {
            throw error;
          })
    },
    getAnalytics: function (context, payload) {
      const findParam = context.state.subscriptionsAnalyticsFilters;

      if (payload.count) {
        findParam.count = payload.count;
      }

      return PnnApi.getAnalytics(findParam)
          .then(res => {
            if (res.email) {
              context.commit('SET_ANALYTICS', res)
              return res;
            }

            throw res;
          })
          .catch(error => { throw error; })
    },
    getSubscriptionStat: function(context) {
      return PnnApi.getSubscriptionStat()
        .then(stat => {
          context.commit('SET_SUBSCRIPTION_STAT', stat);
        })
    },
    createSubscription: function(context, body) {
      return PnnApi.createSubscription(body)
        .then(subscription => {
          context.commit('PUSH_SUBSCRIPTION', subscription);
        })
    },
    getSubscriptions: function (context, data) {
      return PnnApi.getSubscription({
        ...data,
        ...context.state.subscriptionFilters
      })
          .then(result => {
            context.commit('SET_SUBSCRIPTIONS', result.findResult)
            context.commit('SET_SUBSCRIPTIONS_COUNTER', result.countResult)
          })
    },
    performSubscription: function (context, payload) {
      const { body } = payload;

      return PnnApi.performSubscription({
        array: body.array,
        email: body.email
      })
          .then(res => {
            if (res.length) {
              return context.dispatch('getSubscriptions', { page: 1, pageSize: 15 })
            }

            throw res;
          })
          .catch(error => {
            throw error;
          })
    },
    getNewList: function (context, payload) {
      return PnnApi.getSubscription({ status: 'new', pageSize: 15, page: payload.page || 1 })
          .then(response => {
            context.commit('SET_SUBSCRIPTIONS_NEW_LIST', response.findResult);

            return response;
          })
    }
  },
  getters: {

  }
}

export default subscriptions;

<template>
  <div :class="`notification__${className} ${isActive ? 'active' : ''}`" class="notification">
    <div @click="close" class="notification__close-btn"><img :src="closeImage" alt=""></div>
    <div v-if="headerText" class="notification__header">{{ headerText }}</div>
    <div @click="open" v-if="bodyText" class="notification__body" v-html="bodyText">{{ bodyText }}</div>
  </div>
</template>

<script>
export default {
  name: 'notification',
  props: {
    className: {
      type: String,
    },
    cookieName: {
      type: String,
      required: true
    },
    headerText: {
      type: String,
    },
    bodyText: {
      type: String,
      required: true
    },
  },
  data: function () {
    return {
      isActive: false,
      closeImage: require('../../assets/icons/close.svg')
    }
  },
  methods: {
    close: function () {
      sessionStorage.setItem(this.cookieName, 'false');
      this.isActive = false;
    },
    open: function () {
      sessionStorage.removeItem(this.cookieName);
      this.isActive = true;
    },
    isClose: function () {
      return sessionStorage.getItem(this.cookieName);
    }
  },
  mounted() {
    if (! this.isClose()) {
      this.open()
    }
  }
}
</script>


<style lang="scss">
.notification {
  background-color: #ffffff;
  border-radius: 20px;
  margin-top: 15px;
  border: 1px solid var(--color-border);
  position: relative;
  box-shadow: 0px 0px 30px -10px #a0a0a0;
  transition: .5s;

  display: none;

  right: -100%;

  &.active {
    transition: .5s;

    right: 0;
  }

  &__beta {
    max-width: 500px;

    & a {
      display: block;

      text-align: left;
    }
  }

  &__header {
    font-size: 13px;
    font-weight: 600;

    padding: 20px 30px;

    border-bottom: 1px solid var(--color-border);
  }

  &__body {
    padding: 30px 30px;
  }

  &__close-btn {
    position: absolute;
    right: 10px;
    top: 10px;

    width: 20px;
    height: 20px;

    cursor: pointer;

    & img {
      display: block;

      width: 100%;
      height: 100%;

      pointer-events: none;
    }
  }
}
</style>

import { config } from '../config'

const host = config.api.host;


export class PnnApi {
  constructor() {
  }

  static smsAeroTestConnect() {
    return PnnApi.get({
      path: 'sms-aero/test-connect'
    })
  }

  static getNewList() {
    return PnnApi.get({
      path: 'subscription/new'
    })
  }

  static getEfficiency({from, to}) {
    return PnnApi.get({
      path: `chart/efficiency?${new URLSearchParams({from, to}).toString()}`
    })
  }

  static visit() {
    return PnnApi.get({
      path: `visit`
    })
  }

  static getModalBuild(insalesId) {
    return PnnApi.get({
      path: `modal-form/modal?insalesId=${insalesId}`
    })
  }

  static getPointsList() {
    return PnnApi.get({
      path: 'subscription/points'
    })
  }

  static performSubscription(body) {
    return PnnApi.post({
      path: 'subscription/perform',
      body
    })
  }

  static updateFormSettings(body) {
    return PnnApi.put({
      path: 'modal-form',
      body
    })
  }

  static getFormSettings() {
    return PnnApi.get({
      path: 'modal-form'
    })
  }

  static getTemplates() {
    return PnnApi.get({
      path: 'template/all',
      headers: {}
    })
  }

  static deleteTemplate(opts) {
    return PnnApi.delete({
      path: 'template',
      body: {
        templateId: opts.templateId
      },
      headers: {}
    })
  }

  static deactivateAll() {
    return PnnApi.post({
      path: 'template/deactivateAll',
      headers: {}
    })
  }

  static activateTemplate(opts) {
    return PnnApi.post({
      path: 'template/activate',
      body: {
        templateId: opts.templateId
      },
      headers: {}
    })
  }

  static updateTemplate(opts) {
    return PnnApi.put({
      path: 'template',
      body: {
        title: opts.title,
        template: opts.template,
        templateId: opts.templateId
      },
      headers: {}
    })
  }

  static createTemplate(opts) {
    return PnnApi.post({
      path: 'template',
      body: {
        title: opts.title,
        template: opts.template
      },
      headers: {}
    })
  }

  static getSubscription(opts) {
    let resultOpts = ''
    for (let key in opts) {
      resultOpts += `${key}=${encodeURIComponent(opts[key])}&`
    }

    return PnnApi.get({
      path: `subscription/?${resultOpts}`,
      headers: {}
    })
  }

  static getSubscriptionStat() {
    return PnnApi.get({
      path: `subscription/statistic`,
      headers: {}
    })
  }

  static createSubscription(body) {
    return PnnApi.post({
      path: 'subscription',
      body,
      headers: {}
    })
  }

  static getSubscriptionParams() {
    return PnnApi.get({
      path: 'subscription/params',
    })
  }


  static getUsersAccount() {
    return PnnApi.get({
      path: 'user/all',
      headers: {}
    })
  }

  static create(body) {
    return PnnApi.post({
      path: 'user',
      body,
      headers: {}
    })
      .catch(error => {
        throw new Error(error);
      })
  }

  static getProductsByIdList(opts) {
    const { arrayId, shopHostUrl } = opts;

    return PnnApi.get({
      path: `insales-resours/get-products?shopUrl=${shopHostUrl}&arrayId=${arrayId}`,
      headers: {}
    })
  }

  static searchProduct(opts) {
    const { value, shopHostUrl } = opts;

    return PnnApi.get({
      path: `insales-resours/search?shopUrl=${shopHostUrl}&searchValue=${value}`,
      headers: {}
    })
  }

  static removeUser(id) {
    return PnnApi.delete({
      path: 'user',
      body: { id },
      headers: {}
    })
  }

  static updateAccount(body) {
    return PnnApi.post({
      path: 'account/update',
      body,
      headers: {}
    });
  }

  static getAccountData() {
    return PnnApi.get({
      path: 'account',
      headers: {}
    });
  }

  static getUserData() {
    return PnnApi.get({
      path: 'user',
      headers: {}
    });
  }

  static initAccount() {
    return PnnApi.post({
      path: 'app-installation/init',
      headers: {},
      body: {}
    });
  }

  static login(opts) {
    const { login, password } = opts;
    const path = 'auth/login';
    const body = {
      username: login,
      password: password
    }

    return PnnApi.post({
      path, body
    })
  }

  static logout() {
      const path = 'auth/logout';

      return PnnApi.post({
        path
      })
    }

  static getAccountDataByInsalesId(id) {
    return PnnApi.get({
      path: `account/insales/${id}`
    })
  }

  static put(opts) {
    return fetch(`${host}/${opts.path}`, {
      method: 'PUT',
      credentials: 'include',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        ...opts.headers || {}
      },
      body: JSON.stringify(opts.body)
    })
        .then(res => res.json())
  }

  static post(opts) {
    return fetch(`${host}/${opts.path}`, {
      method: 'POST',
      credentials: 'include',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        ...opts.headers || {}
      },
      body: JSON.stringify(opts.body)
    })
        .then(res => res.json())
        .catch(error => { throw new Error(error) })
  }

  static delete(opts) {
    return fetch(`${host}/${opts.path}`, {
      method: 'DELETE',
      credentials: 'include',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        ...opts.headers || {}
      },
      body: JSON.stringify(opts.body)
    })
        .then(res => res.json())
  }

  static get(opts) {
    return fetch(`${host}/${opts.path}`, {
      method: 'GET',
      credentials: 'include',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        ...opts.headers || {}
      },
    })
        .then(res => res.json())
  }

}

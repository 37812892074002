import { PnnApi } from "../../api/pnn-api";
import crypto from "crypto";

const user = {
  state: () => ({
    userData: undefined,
    userList: []
  }),
  mutations: {
    SET_USER_DATA: function (state, data) {
      state.userData = data;
    },
    SET_USER_LIST_DATA: function (state, data) {
      state.userList = data;
    },
    PUSH_USER_LIST_DATA: function (state, data) {
      state.userList.push(data)
    },
    UNPUSH_USER_LIST_DATA: function (state, id) {
      state.userList = state.userList.filter(item => item.id !== id);
    }
  },
  actions: {
    removeUser: function (context, payload) {
      return PnnApi.removeUser(payload.id)
          .then(() => {
            context.commit('UNPUSH_USER_LIST_DATA', payload.id);
          })
    },
    createUser: function(context, payload) {
      return PnnApi.create({
        login: payload.login,
        password: crypto.createHash('md5').update(payload.password).digest('hex'),
        email: payload.email,
        name: payload.name
      })
        .then(data => {
          if (data.id) {
            context.commit('PUSH_USER_LIST_DATA', data);
          }

          return data;
        })
        .catch(error => {
          throw new Error(error);
        })
    },
    getUserData: function(context) {
      return PnnApi.getUserData()
          .then(userData => {
            context.commit('SET_USER_DATA', userData);
          })
          .catch(() => {
            context.dispatch('logoutAccount')
          })
    },
    getUserList: async function(context) {
      return PnnApi.getUsersAccount()
          .then(userArray => {
            context.commit('SET_USER_LIST_DATA', userArray);
          })
    }
  },
  getters: {

  }
}

export default user;

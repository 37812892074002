import { PnnApi } from "../../api/pnn-api";

const auth = {
  state: () => ({}),
  mutations: {

  },
  actions: {
    logoutAccount: async function () {
      return await PnnApi.logout()
        .then(response => {
          if (response.status === 'ok') {
            window.location.href = '/';

            return response;
          }

          throw new Error(response);
        })
    },
    loginAccount: async function (context, payload) {
      const { login, password} = payload;

      return await PnnApi.login({ login, password })
          .then(res => res);
    }
  },
  getters: {

  }
}

export default auth;

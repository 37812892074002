import { PnnApi } from "../../api/pnn-api";

const products = {
    state: () => ({
        productsNewSubscription: {},
    }),
    mutations: {
        SET_PRODUCTS_NEW_SUBSCRIPTIONS: function (state, list) {
            state.productsNewSubscription = list
        }
    },
    actions: {
        getProductsNewSubscriptions: function (context, payload) {
            return PnnApi.getProductsByIdList(payload)
                .then(response => {
                    if (response.status === 'ok') {
                        const list = response.products.reduce((acc, item) => {
                            if (acc.find(elem => elem.id === item.id)) {
                                return acc; // если значение уже есть, то просто возвращаем аккумулятор
                            }
                            return [...acc, item];
                        }, []);
                        context.commit('SET_PRODUCTS_NEW_SUBSCRIPTIONS', list)
                        return response.products;
                    }

                    throw new Error(response);
                })
        }
    },
    getters: {

    }
}

export default products;

<template>
  <div class="message" :class="classList">
    <div class="message__content">
      <div class="message__value">{{ data.message }}</div>
      <div v-if="data.description" class="message__description">{{ data.description }}</div>
    </div>
    <div class="message__icon">
      <img v-if="data.type === 'warning'" :src="icons.warning" alt="">
      <img v-if="data.type === 'success'" :src="icons.success" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-message',
  props: {
    className: {
      type: String,
    }
  },
  data: function () {
    return {
      data: {
        message: '',
        description: '',
        type: 'warning',
      },
      isActive: false,
      icons: {
        warning: require('../../assets/icons/warning.svg'),
        success: require('../../assets/icons/succes.svg')
      }
    }
  },
  computed: {
    classList: function () {
      let classList = '';
      if (this.className)  {
        classList += this.className
      }
      if (this.data.type === 'warning')  {
        classList += ' message__warning'
      }
      if (this.data.type === 'success')  {
        classList += ' message__success'
      }
      if (this.isActive) {
        classList += ' active'
      }
      return classList
    }
  },
  methods: {
    createMessage: function (opts) {
      this.data.type = opts.type || 'warning';
      this.data.message = opts.message || 'Неизвестная ошибка';
      this.data.description = opts.description || '';
    },
    open: function (opts) {
      this.createMessage(opts)
      this.isActive = true;

      setTimeout(() => { this.close() }, 3000)
    },
    close: function () {
      this.isActive = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.message {
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  border-radius: 30px;
  padding: 13px 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  letter-spacing: 0.01em;
  color: #747988;
  display: none;
  z-index: 999;
  &.active {
    display: flex;
  }
  &__warning {
    border: 1px solid var(--color-red);
  }
  &__success {
    border: 1px solid var(--color-green);
  }
  &__content {
    flex-grow: 1;
    padding-right: 30px;
  }
  &__description {
    font-size: 13px;
    line-height: normal;
    font-weight: 400;
    opacity: .9;
  }
  &__icon {
    & img {
      display: block;
    }
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import { PnnApi } from '../api/pnn-api';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home'),
    meta: {
      layout: 'default-layout'
    }
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/products'),
    meta: {
      layout: 'default-layout'
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/settings'),
    meta: {
      layout: 'default-layout'
    }
  },
  {
    path: '/users-settings',
    name: 'users-settings',
    component: () => import('../views/users-settings'),
    meta: {
      layout: 'default-layout'
    }
  },
  {
    path: '/init-setting',
    name: 'init-setting',
    component: () => import('../views/init-setting'),
    meta: {
      layout: 'default-layout'
    }
  },
  {
    path: '/sms',
    name: 'sms',
    component: () => import('../views/sms'),
    meta: {
      layout: 'default-layout'
    }
  },

  {
    path: '/template-message',
    name: 'template-message',
    component: () => import('../views/template-message'),
    meta: {
      layout: 'default-layout'
    }
  },
  {
    path: '/form-settings',
    name: 'form-settings',
    component: () => import('../views/form-settings'),
    meta: {
      layout: 'default-layout'
    }
  },
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('../views/auth/login'),
    meta: {
      layout: 'auth-layout',
      guest: true,
    }
  },
  {
    path: '/auth/insales-auto-login',
    name: 'insales-login',
    component: () => import('../views/auth/insales-login'),
    meta: {
      layout: 'auth-layout',
      guest: true,
    }
  },
  {
    path: '/documentation',
    name: 'documentation',
    component: () => import('../views/documentation'),
    meta: {
      layout: 'default-layout',
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;

router.beforeEach(async (to, from, next) => {
  const isLogin = await PnnApi.getUsersAccount()
    .then(response => {
      if (response && response.statusCode && response.statusCode === 401) {
        return false;
      } else {
        return true;
      }

    })
    .catch(() => {
      return false;
    });

  console.log('isLogin', isLogin)

  if (isLogin) {
    // Для авторизованных пользователей
    if (! to.meta.guest) {
      next();
    } else {
      router.push({ name: 'home' })
    }
  }

  if (! isLogin) {
    if (to.meta.guest) {
      next();
    } else {
      router.push({ name: 'login' })
    }
  }
})

import { PnnApi } from "../../api/pnn-api";

const account = {
  state: () => ({
    accountData: {}
  }),
  mutations: {
    SET_ACCOUNT_DATA: function (state, data) {
      state.accountData = data;
    }
  },
  actions: {
    getAccountData: async function(context) {
      const data = await PnnApi.getAccountData()
          .catch(() => {
            context.dispatch('logoutAccount')
          })

      context.commit('SET_ACCOUNT_DATA', data);
    },
    updateAccount: async function(context, payload) {
      return PnnApi.updateAccount(payload)
          .then(res => {
            context.commit('SET_ACCOUNT_DATA', res);
          })
    }
  },
  getters: {

  }
}

export default account;

<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
  export default {
    name: 'app',
    computed: {
      layout() {
        return this.$route.meta.layout || 'empty-layout';
      }
    },
    mounted() {
      console.log('process.env.ENV', process.env.NODE_ENV)
    }
  }
</script>

<style lang="scss">
@import "./assets/style/global.css";
</style>

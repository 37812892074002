<template>
  <button @click="emit" :class="className" :style="{ minWidth: width, maxWidth: width, height }" class="pnnser-button" :type="type ? type : 'button'">{{ value }}</button>
</template>

<script>
export default {
  name: 'app-button',
  props: {
    type: {
      type: String,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    className: {
      type: String,
    },
    value: {
      type: String,
      required: true
    }
  },
  methods: {
    emit: function (e) {
      this.$emit('click', e.target)
    }
  }
}
</script>

<style lang="scss">
.pnnser-button {
  height: 50px;
  width: 100%;
  border-radius: 30px;
  background: var(--color-violet);
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  cursor: pointer;
  &:hover {
    background-color: var(--color-violet-dark);
  }

  &.mode-gray {
    background-color: var(--color-black);

    &:hover {
      background-color: var(--color-dark);
    }
  }
}
</style>
